// Hide Chatbot
export const hideFreshchat = () => {
    const delaySetup = () => {
        if (window.fcWidget) {
            // Freshchat is already loaded, set up event listener
            window.fcWidget.hide();
        } else {
            // Freshchat is not loaded, retry in a short time
            setTimeout(delaySetup, 50);
        }
    };
    if (process.env.NEXT_PUBLIC_FRESHCHAT_WIdGET_ID !== 'FC-dummy') delaySetup();
};
